<template>
  <div id="page-user-list">
    
      <form method="POST" enctype="multipart/form-data"  @submit.prevent="submitForm">
       <div class="vx-row mb-6">

        <div class="vx-col w-full md:w-2/3">
          <div class="vx-card p-6 mb-6">
            <NoticeForm></NoticeForm>
          
          </div>
        </div>
       </div>

            <!-- Save & Reset Button -->
        <div class="vx-card p-4 mb-4">
            <SettingInfo></SettingInfo>
 
            <div class="flex flex-wrap items-center justify-end">
              <vs-button button="submit" class="ml-auto" >Save Changes</vs-button>
            </div>
        </div>
      </form> 
	</div>
</template>

<script>
import vSelect from 'vue-select'
import NoticeForm from './common/NoticeForm';
import SettingInfo from './common/SettingInfo';
import loaderMixin from '../../../mixins/loaderMixin';
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    vSelect,
    NoticeForm,
    SettingInfo
  },
  mixins: [loaderMixin],
  computed: {
    ...mapGetters({
        form: 'notice/getFormObj',
    }),
    getNoticeId() {
        return this.$route.params.noticeId;
    },
  },
  created() {
    this.clearForm();
    this.clearValidationErrors();
     this.openLoading();
     this.fetchAndSetNoticeById(this.getNoticeId).then(() => {
       this.closeLoading();
     }).catch((r) => this.closeLoading());
  },
  methods: {
    ...mapActions({
      updateNoticeAction: 'notice/updateNoticeAction',
      fetchAndSetNoticeById: 'notice/fetchAndSetNoticeById',
      clearForm: 'notice/clearForm',
      clearValidationErrors: 'validation/clearValidationErrors',
    }),
    submitForm() {
     this.openLoading();
      this.updateNoticeAction({
        noticeId: this.getNoticeId,
        data: this.form
      }).then( async response => { 
        if(response.status === 202) {
            this.closeLoading();
            this.$router.push('/notices');}
      }).catch(() => this.closeLoading());
    },
  },
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
